<template>
  <fieldset>
    <DocFieldsZuEgrn
      :modelValue="data"
      :document-type-id="documentTypeId"
      :document-reg-num="documentRegNum"
      @update:modelValue="$emit('update:modelValue', $event)"
    >
      <template #additional-fields>
        <MultiSubjectField
            :modelValue="modelValue.developers"
            @update:modelValue="update('developers', $event, v$.developers)"
            :errors="v$.$error ? v$.developers.$errors : []"
            :itemValidationRule="developerRules"
            data-qa="developer"
        >
          <label class="label label--required">Застройщик</label>
          <template #add>Добавить</template>
        </MultiSubjectField>
      </template>
      <template #additional-fields-bottom>
        <MultiSelectField
          :modelValue="modelValue.oks"
          @update:modelValue="update('oks', $event, v$.oks)"
          :options="vectors"
          @reload="fetchWithDelay"
          :itemValidationRule="itemValidationRule"
          data-qa="capitalObjects"
          keyProp="externalId"
        >
          <label class="label">Объекты капитального строительства</label>
          <template #add>Добавить</template>
        </MultiSelectField>
      </template>
    </DocFieldsZuEgrn>
  </fieldset>
</template>

<script>
import MultiSubjectField from "../../basic/form/MultiSubjectField.vue";
import DocFieldsZuEgrn from "./DocFieldsZuEgrn.vue";
import DocFieldsNotificationAboutBuilding from "../../../models/documents/docFieldsNotificationAboutBuilding";
import { toRefs } from "vue";
import { helpers, required } from "@vuelidate/validators";
import useValidator from "./validator";
import MultiSelectField from "../../basic/MultiSelectField.vue";
import { useProjectHB } from "../../../hooks/vector";
import subjectValidators from '../../../models/subjectValidators';

export default {
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => new DocFieldsNotificationAboutBuilding(),
    },
    documentTypeId:{
      type: Number,
      required: true
    },
    documentRegNum:{
      type: String,
      required: false
    }
  },
  components: { DocFieldsZuEgrn, MultiSubjectField, MultiSelectField },
  setup(props, { emit }) {
    const { modelValue: data } = toRefs(props);
    const { vectors, fetchWithDelay } = useProjectHB();

    function update(fieldName, value, field) {
      const newValue = { ...props.modelValue };
      newValue[fieldName] = value;
      if (field) field.$model = value;
      emit("update:modelValue", newValue);
    }

    let rules = {
      // oks: {
      //   required: helpers.withMessage("Заполните поле", required),
      // },
      developers: {
        required: helpers.withMessage("Заполните поле", required),
      },
    };

    let developerRules = {
      ...subjectValidators
    }


    let itemValidationRule = {
        externalId: helpers.withMessage("Заполните или удалите неиспользуемое поле", (value) => {
          return value !== null && value["externalId"] !== undefined
        })
    }

    const v$ = useValidator(data, rules);

    return { data, update, v$, vectors, fetchWithDelay, itemValidationRule, developerRules };
  },
};
</script>